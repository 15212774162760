/*import React from 'react';
import styled from '@emotion/styled';

const StyledImage = styled.img({
  height: 64
});

export default function Logo() {
  return (
    <StyledImage
      alt="Nextmol"
      src="./images/logo-nextmol.png"
    />
  );
}*/
import React from "react";
import logo from "./images/logo-nextmol.png";

export default function Logo() {
  return (
    <img
      src={logo}
      //width="100"
      height="48"
      alt="Nextmol"
    />
  );
}

