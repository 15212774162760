// src/gatsby-theme-apollo-docs/components/seo.js
import React from 'react';
import {Helmet} from 'react-helmet';

export default function SEO({title, description, siteName}) {
  return (
    <Helmet>
      <link rel="./images/logo-nextmol.png"/>
      {/* other SEO tags (OpenGraph, Twitter, etc.) */}
    </Helmet>
  );
}